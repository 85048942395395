<template>
  <div class="text-center">
    <h1 class="mt-30">ADMIN PANEL</h1>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "webShopDashboard",
  components: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    
  }
};
</script>
